<template>
  <div class="white text-body-2 black--text mt-5 mb-5 pt-6 pb-6 pl-8 pr-8 d-flex align-center rounded-lg"
    :class="{ 'no-paid': !item.paid }">
    <div class="info--number font-weight-bold info--number text-truncate">
      {{ item._id.slice(-5) }}
    </div>
    <div class="info--name text-truncate text-uppercase pr-2">
      {{ item.name }}
    </div>
    <div class="info--phone">{{ item.movilNumber }}</div>
    <div class="info--address text-uppercase text-truncate">
      {{ item.address }}
    </div>
    <div class="info--actions d-flex justify-center">
      <v-btn class="mr-2" depressed outlined color="primary" @click="$emit('detail', item._id)">VER DETALLES</v-btn>
      <v-btn class="mr-2" depressed outlined color="primary" @click="$emit('edit', item._id)">
        <v-icon> mdi-pencil-outline </v-icon>
      </v-btn>
      <v-btn v-if="showDelete" depressed outlined color="primary" @click="$emit('delete', item)">
        <v-icon> mdi-delete-empty-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
// Bus
import Bus from "../../../common/EventBus";

export default {
  name: "ClientTemporalTableItem",
  mounted() {
    const localData = JSON.parse(localStorage.LCInternet);
    this.showDelete = localData.user.rol === "admin";
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showDelete: false
  }),
  methods: {
    prueba(id) {
      Bus.$emit("prueba", id);
    }
  }
};
</script>

<style lang="scss" scoped >
.no-paid {
  border: 1px solid rgb(224, 173, 173) !important;
  background-color: rgb(255, 240, 240) !important;
}
</style>